<template>
  <b-card no-body class="mb-1">
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button block v-b-toggle.accordion-credit-card variant="info"
        >Cartão de Crédito</b-button
      >
    </b-card-header>
    <b-collapse
      id="accordion-credit-card"      
      accordion="my-accordion"
      role="tabpanel"
    >
      <b-card-body>
        <b-row class="justify-content-center">
          <b-col md="10" class="bank-card">
            <v-credit-card
              @cardChanged="cardChanged"
              @change="dataChanged"
              :trans="creditCardFields"
              direction="column"
            />
            <div class="d-block my-2">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="option1"
                  v-model="recurrent"
                />
                <label class="form-check-label" for="inlineCheckbox1"
                  >Automatizar pagamento, tornar este pagamento
                  recorrente.</label
                >
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-collapse>
  </b-card>

  <!-- <v-credit-card
      @cardChanged="cardChanged"
      @change="dataChanged"
      :trans="creditCardFields"
      direction="row"
    />

    <div class="d-block my-2">
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="checkbox"
          id="inlineCheckbox1"
          value="option1"
          v-model="recurrent"
        />
        <label class="form-check-label" for="inlineCheckbox1"
          >Automatizar pagamento, tornar este pagamento recorrente.</label
        >
      </div>
    </div> -->
</template>

<script>
import VCreditCard from "v-credit-card";
import "v-credit-card/dist/VCreditCard.css";
export default {
  
  data() {
    return {
      recurrent: false,
      credit_card: {
        id: "",
        holder_name: "",
        number: "",
        expiration_date: "", // '2021-12',
        cvv: "",
        card_brand: "",
      },
      creditCardFields: {
        name: {
          label: "Nome",
          placeholder: "Nome impresso no cartão",
        },
        card: {
          label: "Número do cartão",
          placeholder: "Número do cartão",
        },
        expiration: {
          label: "Vencimento",
        },
        security: {
          label: "CVV",
          placeholder: "Código de segurança",
        },
      },
    };
  },
  components: {
    VCreditCard,
  },
  computed:{
    clientData() {
      return this.$store.state.checkout.clientData;
    },
    unityData(){
      return this.$store.state.checkout.unityData
    },
  },
  methods: {
    cardChanged(val) {
      if (val) {
        this.credit_card.card_brand = val.toUpperCase();
      }
    },
    dataChanged(data) {
      this.credit_card.holder_name = data.name;
      this.credit_card.number = data.cardNumber;
      this.credit_card.expiration_date = data.expiration;
      this.credit_card.cvv = data.security;
    },
  },
};
</script>

<style>
</style>